(function()
{
	'use strict';
	angular.module('datatables', [ 'atsc', 'ngMaterial', 'ngResource' ]).controller('DataTableController', ['$scope', '$mdDialog', '$http', '$element', '$compile', 'DataTableRenderer', '$q', '$util', '$timeout', '$rootScope', '$parse', function($scope, $mdDialog, $http, $element, $compile, DataTableRenderer, $q, $util, $timeout, $rootScope, $parse)
	{
		var controller																= this;

		var queryid																	= $element.attr('data-queryid');
		var options																	= JSON.parse($element.attr('data-options'));
		var columns																	= JSON.parse($element.attr('data-columns'));
		var selectedRows															= [];
		var updatingFilter															= false;
		var currentFilter															= null;
		var lastFilter																= null;
		var searchFilters															= {};
		var searchTerm																= '';
		var lastSearchTerm															= '';
		var userHiddenColumns														= [];
		var displaysHiddenColumns													= [];
		var checkScrollbar															= function(){};
		var colReorderEnabled														= false;
		var table;
		var $tableElement;

		var state																	= null; // the currently loaded state
		var location																= null;
		var currentState															= null; // the current 'saved' state
		var stateSupportsGlobal														= $element.attr('data-state-supports-global') == 'true';
		var availableStates															= null;

		try																			{ state = JSON.parse($element.attr('data-state')); }
		catch (ex)																	{ /* no problem */ }

		try																			{ location = $element.attr('data-location'); }
		catch (ex)																	{ /* no problem */ }

		try																			{ availableStates = JSON.parse($element.attr('data-states')); }
		catch (ex)																	{ /* no problem */ }

		if (state)																	{ currentState = angular.copy(state); }

		var setNumSearchFilters = function()
		{
			var i = 0;

			if (searchFilters)
			{
				for (var key in searchFilters)
				{
					if (searchFilters[key])											{ i++; }
				}
			}

			$scope.numSearchFilters													= i;
		};

		var resetColumnVisibility = function()
		{
			try
			{
				if (table)
				{
					var windowWidth													= $(window).width();
					var hiddenColumns												= [];
					var numVisibleColumns											= 0;
					var i, j;

					for (i = 0; i < userHiddenColumns.length; i = i + 1)			{ hiddenColumns.push(userHiddenColumns[i]); }

					for (i = 0; i < columns.length; i = i + 1)
					{
						if (hiddenColumns.indexOf(columns[i].name) == -1 && columns[i].visibility == 'browser')
						{
							hiddenColumns.push(columns[i].name);
						}
					}

					for (i = 0; i < columns.length; i = i + 1)
					{
						if (columns[i].name && columns[i].visibility != 'browser' && hiddenColumns.indexOf(columns[i].name) == -1)
						{
							numVisibleColumns++;
						}
					}

					displaysHiddenColumns											= hiddenColumns;

					var $thead														= table.settings()[0].nTHead;

					for (j = 0 ; j < columns.length; j = j + 1)
					{
						table.columns(j).visible(hiddenColumns.indexOf(columns[j].name) == -1, false);
					}

					table.fixedHeader.adjust();
					table.columns.adjust();
					checkScrollbar();

					$scope.numVisibleColumns										= numVisibleColumns;
				}
			}
			catch (ex)
			{
				if (console)														{ console.log(ex); }
			}
		};

		var setState = function()
		{
			if (state)
			{
				searchFilters														= state.filters ? angular.copy(state.filters) : {};

				setNumSearchFilters();

				searchTerm															= state.searchTerm ? state.searchTerm : '';

				userHiddenColumns													= state.hiddenColumns ? angular.copy(state.hiddenColumns) : [];
				resetColumnVisibility();

				if (colReorderEnabled)
				{
					var colOrder													= state.colOrder ? state.colOrder : [];
					var desiredLength												= table.colReorder.order().length;

					if		(desiredLength > colOrder.length)
					{
						for (var i = colOrder.length; i < desiredLength; i = i + 1)	{ colOrder.push(i); }
					}
					else if (desiredLength < colOrder.length)
					{
						var index;

						for (var i = colOrder.length; i >= desiredLength; i = i - 1)
						{
							index													= colOrder.indexOf(i);

							if (index > -1)											{ colOrder.splice(index, 1); }
						}
					}

					table.colReorder.order(colOrder);
				}

				if (controller.reloadIfChanged)										{ controller.reloadIfChanged(); }
			}
		};

		var loadState = function(name, global)
		{
			return $q(function(resolve, reject)
			{
				$http({
					url: (options.uriprefix || '') + '/lib/atsc/datatables/queries/' + queryid + '/state',
					method: 'get',
					params: { 'href': location, 'name': name, 'global': global }
				}).then(function(response)
				{
					if (response && response.data)
					{
						state														= response.data;
						currentState												= angular.copy(state);

						setState();

						$scope.stateChanged											= false;

						resolve();
					}
				}, reject)
			});
		}

		var saveState = function(name, global)
		{
			return $q(function(resolve, reject)
			{
				$http({
					url: (options.uriprefix || '') + '/lib/atsc/datatables/queries/' + queryid + '/state',
					method: 'post',
					data: { 'state': state, 'href': location, 'name': name, 'global': global }
				}).then(function(response)
				{
					currentState													= angular.copy(state);
					$scope.stateChanged												= false;

					if (response && response.data && response.data.data && response.data.data.items && response.data.data.items.length > 0)
					{
						availableStates												= response.data.data.items[0];
					}

					resolve();
				}, reject);
			});
		};

		var deleteState = function(name, global)
		{
			return $q(function(resolve, reject)
			{
				$http({
					url: (options.uriprefix || '') + '/lib/atsc/datatables/queries/' + queryid + '/state',
					method: 'delete',
					data: { 'href': location, 'name': name, 'global': global }
				}).then(function(response)
				{
					if (response && response.data && response.data.data && response.data.data.items && response.data.data.items.length > 0)
					{
						availableStates												= response.data.data.items[0];
					}

					resolve();
				}, reject);
			});
		};

		var resetState = function()
		{
			if ($scope.stateChanged)
			{
				return $q(function(resolve, reject)
				{
					state = angular.copy(currentState);

					setState();

					resolve();
				});
			}
			else
			{
				return loadState('default', false);
			}
		};

		var checkState = function()
		{
			if (state)
			{
				state.hiddenColumns													= angular.copy(userHiddenColumns);
				state.colOrder														= colReorderEnabled && table.colReorder ? table.colReorder.order() : null;
				state.filters														= angular.copy(searchFilters);
				state.searchTerm													= searchTerm;
			}

			$scope.stateChanged														= !angular.equals(state, currentState);
		};

		if (state == null || !state.hiddenColumns)
		{
			if (!state)																{ state = {}; }
			state.hiddenColumns														= [];

			for (var i = 0; i < columns.length; i = i + 1)
			{
				if (columns[i].visibility == 'hidden')								{ state.hiddenColumns.push(columns[i].name); }
			}
		}

		$scope.stateChanged															= false;

		setState();

		setNumSearchFilters();

		$scope.info																	= '';

		controller.id																= $element.attr('data-id');
		controller.queryid															= queryid;
		$scope.showFiltersInline													= options.filtersRequired;

		if (!$scope.$parent.childTableControllers)									{ $scope.$parent.childTableControllers = []; }
		$scope.$parent.childTableControllers.push(controller);

		var parentChildControllersIndex												= $scope.$parent.childTableControllers.length - 1;

		$scope.$on('$destroy', function()
		{
			$scope.$parent.childTableControllers.splice(parentChildControllersIndex, 1);
		});

		var renderCheckbox = function(tableController, name, value, display, data)
		{
			if (data._empty)														{ return ''; }
			else
			{
				var checked															= false;

				for (var i = 0; i < selectedRows.length; i = i + 1)
				{
					if (selectedRows[i]._id == data._id)
					{
						checked														= true;

						if (selectedRows[i]._incomplete)
						{
							for (var key in data)									{ selectedRows[i][key] = data[key]; }

							selectedRows[i]._incomplete								= false;
						}

						break;
					}
				}

				return '<input type="checkbox" class=\"checkbox\" value=\"' + data._id + '"' + (checked ? ' checked' : '') + ' />';
			}
		};

		var renderTitle = function(tableController, name, value, display, data)
		{
			if (data._empty)														{ return ''; }
			else
			{
				// TODO: Only apply title if it doesn't fit in the available space.
				return $('<span></span>').text(value).attr('title', value).outerHTML();
			}
		};

		var renderAsActions = function(tableController, name, value, display, data)
		{
			if (data._empty)														{ return ''; }
			else
			{
				var actions															= JSON.parse(value);
				var html															= '';
				var hasDefault														= false;
				var sep																= '';

				for (var i = 0; i < actions.length; i = i + 1)
				{
					html															= html + sep;

					if (actions[i].def)
					{
						if (hasDefault)												{ actions[i].def = false; }
						hasDefault													= true;
					}

					html															= html + '<div style="display: inline-block; width: 20px; text-align: center;' + (actions[i].def ? ' display: none;' : '') + '">' + $('<i></i>').attr('class', (actions[i].icon.startsWith('fa') ? actions[i].icon : 'fa fa-' + actions[i].icon) + (actions[i].def ? ' default' : '')).attr('title', actions[i].title).attr('data-name', actions[i].name).attr('data-onclick', actions[i].onclick).outerHTML() + '</div>';

					if (actions[i].def)												{ sep = ''; }
					else															{ sep = '&nbsp;&nbsp;&nbsp;'; }
				}

				return html;
			}
		}

		var calculateColumns = function()
		{
			var ncolumns															= [];
			var i;

			for (i = 0; i < columns.length; i = i + 1)								{ ncolumns.push($.extend(true, {}, columns[i])); }

			var $tableElement														= $element.find('.dataTables_scrollHead > table');
			if ($tableElement.length == 0)											{ $tableElement = $element; }

			var maxWidth, autoWidth, availWidth, rankedColumns;

			maxWidth																= $tableElement.innerWidth();

			// If the scroller is enabled, reserve 20px for the scrollbar.
			if (options.scroller)													{ maxWidth = maxWidth - 20; }

			autoWidth																= 0;
			availWidth																= maxWidth;
			rankedColumns															= [];

			// Convert '10px' (string) to '10' (number), and calculate the available width.
			for (i = 0; i < ncolumns.length; i = i + 1)
			{
				if (displaysHiddenColumns.indexOf(columns[i].name) > -1)			{ ncolumns[i].width = 0; }
				if (('' + ncolumns[i].width).endsWith('px'))						{ ncolumns[i].width = parseFloat(ncolumns[i].width.left('px')); }
				if (typeof(ncolumns[i].width) == 'number')							{ availWidth = availWidth - ncolumns[i].width; }
			}

			// Convert '10%' (string) to the width in pixels (number) from the calculated available width.
			for (i = 0; i < ncolumns.length; i = i + 1)
			{
				if (('' + ncolumns[i].width).endsWith('%'))							{ ncolumns[i].widthType = 'percentage'; ncolumns[i].width = Math.floor(parseFloat(ncolumns[i].width.left('%')) * (availWidth / 100), 2); }
			}

			// Recalculate the available width and count the columns which have no width set.
			availWidth																= maxWidth;

			for (i = 0; i < ncolumns.length; i = i + 1)
			{
				if (typeof(ncolumns[i].width) == 'number')							{ availWidth = availWidth - ncolumns[i].width; }
				else																{ autoWidth++; }
			}

			// Do we have space left? If so, this is either "a lot" (because we have columns w/o a width that need to be filled in) or only a few pixels (due to rounding differences in the formulas above this line)
			if (availWidth > 0)
			{
				// Are there columns without a width set?
				if (autoWidth > 0)
				{
					// Provide the columns which have no width set with a width (available / total columns to divide).

					autoWidth														= Math.floor(availWidth / autoWidth);

					for (i = 0; i < ncolumns.length; i = i + 1)
					{
						if (typeof(ncolumns[i].width) != 'number')					{ ncolumns[i].width = autoWidth; }
					}
				}
				else
				{
					// Dump the extra width on the first percentage column we find.

					for (i = 0; i < ncolumns.length; i = i + 1)
					{
						if (ncolumns[i].widthType == 'percentage')					{ ncolumns[i].width = ncolumns[i].width + availWidth; break; }
					}
				}
			}

			var renderer;

			// Set other (non-width) properties for columns.
			for (i = 0; i < ncolumns.length; i = i + 1)
			{
				if (!ncolumns[i].label)												{ ncolumns[i].label = ncolumns[i].name; }
				if (!ncolumns[i].sortable)											{ ncolumns[i].sortable = false; }

				renderer															= ncolumns[i].type ? DataTableRenderer.getRenderer(ncolumns[i].type) : null;

				if		(renderer)													{ ncolumns[i].render = renderer.bind(null, controller, ncolumns[i].name); }
				else if (ncolumns[i].data == '_checkbox')							{ ncolumns[i].render = renderCheckbox.bind(null, controller, ncolumns[i].name); }
				else if (ncolumns[i].data == '_actions')							{ ncolumns[i].render = renderAsActions.bind(null, controller, ncolumns[i].name); }
				else																{ ncolumns[i].render = renderTitle.bind(null, controller, ncolumns[i].name); }

				delete ncolumns[i].type;
			}

			return ncolumns;
		};

		var replaceFields = function(input)
		{
			return $q(function(resolve, reject)
			{
				if (input)
				{
					var output														= '';
					var m;
					var tableId;
					var fieldName;
					var fieldValue;
					var table;
					var rows;

					$util.loop().perform(function()
					{
						return $q(function(next, exit)
						{
							m														= (input ? ('' + input) : '').match(/\{\{([^\}]*)\}\}/);

							if (m == null)											{ exit(); }
							else
							{
								if (m[1].indexOf('.') > -1)
								{
									tableId											= m[1].left('.');
									fieldName										= m[1].right('.');
								}

								fieldValue											= '';

								output												= output + input.substring(0, input.indexOf(m[0]));
								input												= input.substring(input.indexOf(m[0]) + m[0].length);

								for (var i = 0; i < $scope.$parent.childTableControllers.length; i = i + 1)
								{
									table											= $scope.$parent.childTableControllers[i];

									if (table.id == tableId)
									{
										rows										= table.getSelectedRows().then(function(rows)
										{
											if (table.getOption('singleSelectionMode'))
											{
												if (rows.length)					{ fieldValue = rows[0][fieldName] || ''; }
												else								{ fieldValue = '_<noresult>_'; }
											}
											else
											{
												fieldValue							= [];

												if (rows.length)
												{
													for (var j = 0; j < rows.length; j = j + 1)
													{
														fieldValue.push(rows[j][fieldName] || '');
													}
												}
												else								{ fieldValue = '_<noresult>_'; }

												fieldValue							= fieldValue.join('_<sep>_');
											}

											output									= output + fieldValue;

											next();
										})

										return;
									}
								}

								next();
							}
						});
					}).then(function()
					{
						output														= output + input;

						resolve(output);
					}).run();
				}
				else
				{
					resolve(null);
				}
			});
		};

		var updateFilter = function()
		{
			return $q(function(resolve, reject)
			{
				if (updatingFilter)													{ reject(); }
				else
				{
					updatingFilter													= true;

					if (options.filters || searchFilters)
					{
						var filters													= {};
						var filter;

						var checkFilters = function(list)
						{
							return $q(function(done)
							{
								if (list)
								{
									$util.forEach(Object.keys(list)).each(function(key, i)
									{
										return $q(function(next, exit)
										{
											replaceFields(list[key]).then(function(value)
											{
												filters[key] = value;

												next();
											}, next)
										});
									}).then(function()
									{
										done();
									}).run();
								}
								else												{ done(); }
							});
						};

						checkFilters(searchFilters).then(function()
						{
							checkFilters(options.filters).then(function()
							{
								currentFilter										= JSON.stringify(filters);
								updatingFilter										= false;
								resolve(currentFilter);
							});
						})
					}
					else
					{
						currentFilter												= null;
						updatingFilter												= false;
						resolve(currentFilter);
					}
				}
			});
		};

		var addSelectedRow = function(row)
		{
			var obj																	= angular.copy(row);

			obj.$fetch = function()
			{
				return $q(function(resolve, reject)
				{
					if (obj._incomplete)
					{
						$http({
							'url': (options.uriprefix || '') + '/lib/atsc/datatables/queries/' + queryid + '/model/' + obj._id,
							'method': 'get',
							'params': processAjaxData({}, true)
						}).then(function(response)
						{
							if (response && response.data && response.data.data && response.data.data.items && response.data.data.items.length > 0)
							{
								for (var key in response.data.data.items[0])
								{
									obj[key]										= response.data.data.items[0][key];
								}

								obj._incomplete										= false;

								resolve();
							}
							else													{ reject(); }
						}, function()
						{
							obj._incomplete											= false;

							reject();
						});
					}
					else															{ resolve(); }
				});
			}

			selectedRows.push(obj);
		};

		controller.getOption = function(key)
		{
			return options[key];
		};

		controller.getVisibleRows = function()
		{
			var data																= table.data();
			var rows																= [];

			for (var i = 0; i < data.length; i = i + 1)								{ rows.push(angular.copy(data[i])); }

			return rows;
		};

		controller.getTableRowElement = function(id)
		{
			var node																= null;

			table.rows().every(function(rowIdx, tableLoop, rowLoop)
			{
				if (this.data()._id == id)											{ node = this.node(); }
			});

			return node;
		};

		controller.getSelectedRows = function(offset, limit, noFetch)
		{
			if (!offset)															{ offset = 0; }
			if (!limit)																{ limit = selectedRows.length; }

			return $q(function(resolve, reject)
			{
				var res																= [];

				$util.forEach(selectedRows).each(function(item, i)
				{
					return $q(function(next, exit)
					{
						if (i < offset || i > limit)								{ next(); }
						else
						{
							var cont												= function(){ res.push(item); next(); };

							if (noFetch)											{ cont(); }
							else													{ item.$fetch().then(cont, cont); }
						}
					});
				}).then(function()
				{
					resolve(res);
				}).run();
			});
		};

		controller.getSelectedRowIDs = function(offset, limit)
		{
			return $q(function(resolve, reject)
			{
				controller.getSelectedRows(offset, limit, true).then(function(rows)
				{
					var ids															= [];
					for (var i = 0; i < rows.length; i = i + 1)						{ ids.push(rows[i]._id); }

					resolve(ids);
				});
			});
		};

		controller.setSelectedRows = function(rows)
		{
			selectedRows.splice(0, selectedRows.length);

			for (var i = 0; i < rows.length; i = i + 1)								{ addSelectedRow({ '_id': rows[i], '_incomplete': true }); }

			$scope.$emit('table.onSetSelectedRows', rows, controller);

			controller.reload();
		};

		controller.getFilters = function()
		{
			return angular.copy(options.filters);
		};

		controller.setFilters = function(filters)
		{
			options.filters															= filters;

			controller.reloadIfChanged();
		};

		controller.setSearchFilters = function(filters)
		{
			searchFilters															= filters;

			setNumSearchFilters();

			checkState();

			controller.reloadIfChanged();
		};

		controller.setSearchTerm = function(term)
		{
			searchTerm																= term;

			checkState();

			controller.reloadIfChanged();
		}

		var tableScrollTop = null;

		controller.reload = function(reset)
		{
			if (table)
			{
				if (reset === false)												{ tableScrollTop = $tableElement.parent()[0].scrollTop; }

				table.draw(reset);
			}
		};

		controller.reloadIfChanged = function()
		{
			updateFilter().then(function(filter)
			{
				if (filter !== lastFilter || searchTerm !== lastSearchTerm)
				{
					controller.reload(0);
				}
			});
		};

		var updateOtherTables = function()
		{
			for (var i = 0; i < $scope.$parent.childTableControllers.length; i = i + 1)
			{
				if ($scope.$parent.childTableControllers[i] != controller)
				{
					$scope.$parent.childTableControllers[i].reloadIfChanged();
				}
			}
		};

		var processAjaxDataArray = function(d, rootKey, a)
		{
			for (var i = 0; i < a.length; i = i + 1)
			{
				processAjaxDataValue(d, rootKey + '[' + i + ']', a[i]);
			}
		};

		var processAjaxDataObject = function(d, rootKey, a)
		{
			for (var key in a)
			{
				processAjaxDataValue(d, rootKey + '[' + key + ']', a[key]);
			}
		};

		var processAjaxDataValue = function(d, key, value)
		{
			if		(value instanceof Array)										{ processAjaxDataArray(d, key, value); }
			else if (value instanceof Object)										{ processAjaxDataObject(d, key, value); }
			else																	{ d[key] = value; }
		};

		var processAjaxData = function(d, includeParams)
		{
			if (includeParams)
			{
				var p																= table ? table.ajax.params() : {};

				if (p)
				{
					for (var key in p)												{ processAjaxDataValue(d, key, p[key]); }
				}
			}

			d.showCheckbox															= options.showCheckbox  ? 'true' : 'false';
			d.showRowNumber															= options.showRowNumber ? 'true' : 'false';
			d.showActionBar															= options.showActionBar ? 'true' : 'false';
			d.filters																= currentFilter;
			lastFilter																= d.filters;
			d.searchTerm															= searchTerm;
			lastSearchTerm															= d.searchTerm;

			return d;
		}

		var calculateRow = function($el)
		{
			// outerHeight = height + padding + margin
			// rowHeight = outerHeight = borderTopWidth + borderBottomWidth
			return $el.outerHeight() + (parseInt($el.css('border-top-width'), 10) || 0) + (parseInt($el.css('border-bottom-width'), 10) || 0);
		}

		var calculateRows = function()
		{
			return $q(function(resolve)
			{
				var $testDiv														= $('<div style="position: absolute !important; left: -9999px !important; top: -9999px !important;">' +
																							'<div class="datatable">' +
																								'<div class="dataTables_wrapper no-footer">' +
																									'<div class="dataTables_length"><label><select><option value="100">100</option></select> results</label></div>' +
																									'<div class="dataTables_filter"><label>Search:<input type="search"></label></div>' +
																									'<table class="unselectable display dataTable no-footer">' +
																										'<thead>' +
																											'<tr><td class="clickable sorting_asc">Test</td></tr>' +
																											'<tr><td><label style="width: 100%;" class="search"><input style="width: 100%;" /></label></td></tr>' +
																										'</thead>' +
																										'<tbody><tr class="odd rowlines-' + options.rowLines + '"><td></td></tr><tr class="even rowlines-' + options.rowLines + '"><td></td></tr></tbody>' +
																									'</table>' +
																									'<div class="dataTables_info">1 tot 18 van 36 resultaten</div>' +
																									'<div class="dataTables_paginate paging_simple_numbers"><a class="paginate_button previous disabled">Vorige</a><span><a class="paginate_button current">1</a><a class="paginate_button ">2</a></span><a class="paginate_button next">Volgende</a></div>' +
																								'</div>' +
																							'</div>' +
																						'</div>');

				$('body').append($testDiv);

				$timeout(function()
				{
					try
					{
						var $el;

						$el																= $testDiv.find('thead tr:nth-of-type(1) td');
						var headerRowHeight												= calculateRow($el);

						$el																= $testDiv.find('thead tr:nth-of-type(2) td');
						var subheaderRowHeight											= calculateRow($el);

						$el																= $testDiv.find('tbody tr:nth-of-type(2) td');
						var rowHeight													= calculateRow($el);
						var height														= $element.height()

						if ((options.scroller !== true && options.lengthChange === true) || options.searching !== false)
						{
							// Pre-header with length change and/or search bar.
							height														= height - Math.max($testDiv.find('.dataTables_length').outerHeight(), $testDiv.find('.dataTables_filter').outerHeight());
						}

						if (options.info !== false || (options.scroller !== true && options.paging !== false))
						{
							// Post-footer with info and/or pagination.
							height														= height - Math.max($testDiv.find('.dataTables_info').outerHeight(), $testDiv.find('.dataTables_paginate').outerHeight());
						}
					}
					finally
					{
						$testDiv.remove();
					}

					// Header
					height															= height - headerRowHeight;

					var visibleRows													= Math.floor(height / rowHeight);

					resolve({ 'rows': visibleRows, 'height': Math.ceil(visibleRows * rowHeight), 'rowHeight': rowHeight });
				}, 0);
			});
		};

		var load = function()
		{
			// The table will not render properly if it isn't visible.
			// Workaround for Angular-CSP.
			if ($('body.ng-cloak').length > 0)										{ setTimeout(load, 10); }
			else
			{
				var i, j;

				if (options.scroller)												{ options.autoSize = true; }

				if (options.showRowNumber)											{ columns.unshift({ 'data': '_rn', 'width': 80, 'sortable': true, 'searchable': false }); }

				if (!options.rowLines)												{ options.rowLines = 1; }

				if (!options.orders)												{ options.orders = []; }

				if (options.orders.length == 0)										{ options.orders.push([ columns[0].name, options.showRowNumber ? 'desc' : 'asc' ]); }

				if (options.showCheckbox)											{ columns.unshift({ 'data': '_checkbox', 'width': 35, 'sortable': false, 'searchable': false }); }

				if (options.showActionBar)											{ columns.push({ 'data': '_actions', 'width': options.actionBarWidth || '80px', 'sortable': false, 'className': 'actions', 'searchable': false }); }

				if (options.allowCopy === undefined)								{ options.allowCopy = true; }

				for (i = 0; i < options.orders.length; i++)
				{
					for (j = 0; j < columns.length; j++)
					{
						if (columns[j].name == options.orders[i][0])
						{
							options.orders[i][0] = j;
							break;
						}
					}
				}

				$tableElement														= $element.find('table.display');

				updateFilter().then(function(filter)
				{
					var width														= $element.width();
					var height														= $element.height();

					var continueLoading = function(d)
					{
						try
						{
							var $loaderElement = null;

							var preInit = function(e)
							{
								$tableElement.off('preInit.dt', preInit);

								var table											= $(this).DataTable();

								var $tableHeaderElement								= $tableElement;

								if ($tableElement.parent().hasClass('dataTables_scrollBody'))
								{
									$tableElement.parent().css('min-height', d.height + 'px');
									$tableHeaderElement								= $tableElement.parent().parent().find('.dataTables_scrollHead table').first();
								}

								// Reset column visibility on resize.
								$(window).on('resize', resetColumnVisibility);

								var setWidth = function(newWidth)
								{
									if (!newWidth)									{ newWidth = $element.width(); }

									width											= newWidth;

									var cols										= table.settings()[0].aoColumns;
									var i, j;

									var ccols										= calculateColumns();

									for (i = 0; i < ccols.length; i = i + 1)
									{
										for (j = 0; j < cols.length; j = j + 1)
										{
											if (cols[j].idx == i)
											{
												cols[j].sWidth						= ccols[i].width; // calculated width
												cols[j].width						= ccols[i].width; // actual width
												cols[j].nTh.style.width				= ccols[i].width + 'px';
											}
										}
									}

									if (options.scroller)
									{
										table.fixedHeader.adjust();
										table.columns.adjust();
									}

									checkScrollbar();
								};

								var checkWidth = function()
								{
									return $q(function(resolve, reject)
									{
										if ($element.is(':visible'))
										{
											var newWidth							= $element.width();

											if (width != newWidth)
											{
												setWidth(newWidth);

												resolve(true);
											}
											else									{ resolve(); }
										}
										else										{ resolve(); }
									});
								};

								var checkHeight = function()
								{
									return $q(function(resolve, reject)
									{
										if ($element.is(':visible') && options.autoSize)
										{
											var newHeight							= $element.height();

											if (height != newHeight && newHeight > 150)
											{
												height								= newHeight;

												calculateRows().then(function(d)
												{
													if (height == newHeight)
													{
														table.page.len(d.rows);

														if (options.scroller)
														{
															if ($tableElement.parent().hasClass('dataTables_scrollBody'))
															{
																$tableElement.parent().css('min-height', d.height + 'px');
															}

															$timeout(function()
															{
																table.scroller.measure(false);
																table.clear();
																resolve(true);
															}, 0);
														}
														else						{ resolve(true); }
													}
													else							{ resolve(); }
												}, resolve);
											}
											else									{ resolve(); }
										}
										else										{ resolve(); }
									});
								};

								var checkSize = function()
								{
									var redraw										= false;

									if ($tableElement.is(':visible'))
									{
										var test = function(lastOffset, lastWidth)
										{
											var offset								= $tableElement.offset().left;
											var width								= $tableElement.width();

											if (lastOffset == offset && lastWidth == width)
											{
												checkHeight().then(function(heightResult)
												{
													checkWidth().then(function(widthResult)
													{
														if (heightResult || widthResult)
														{
															controller.reload(false);
														}
													});
												});
											}
											else
											{
												$timeout(test.bind(this, offset, width), 100);
											}
										};

										$timeout(test.bind(this, $tableElement.offset().left, $tableElement.width()), 100);
									}
								};

								if (MutationObserver)
								{
									// Observe the container and all parent elements for changes to the "style" or "class" attribute. Recalculate if necessary.
									var observer									= new MutationObserver(checkSize);
									var observerOptions								= { 'attributes': true, 'attributeFilter': [ 'style', 'class' ] };

									var $parent										= $element;

									while ($parent && $parent.length > 0 && !$parent.is('html'))
									{
										$parent										= $parent.parent();

										try											{ observer.observe($parent[0], observerOptions); }
										catch (ex)									{ /* no problem */ }
									}
								}

								// Recalculation may also be needed if the window is resized.
								$(window).on('resize', checkSize);

								var pageLength										= 0;
								var firstDraw										= true;

								$tableElement.on('draw.dt', function()
								{
									if (!options.scroller)
									{
										$timeout(function()
										{
											$scope.info								= $element.find('.dataTables_info').html();

											$scope.$applyAsync(function()
											{
												$scope.info							= $element.find('.dataTables_info').html();

												$timeout(function()
												{
													$scope.info						= $element.find('.dataTables_info').html();

													$element.find('.datatable-refresh.fa-refresh').off('click').on('click', function()
													{
														controller.reload();
													});

													$element.find('.datatable-refresh.fa-refresh').each(function()
													{
														this.onselectstart = function(){ return false; };
													});
												}, 0);
											});
										}, 0);
									}

									if (tableScrollTop !== null)
									{
										try
										{
											$tableElement.parent()[0].scrollTop		= tableScrollTop;
										}
										finally										{ tableScrollTop = null; }
									}

									$element.find('.datatable-refresh.fa-refresh').removeClass('fa-spin');

									if ($loaderElement)
									{
										$loaderElement.remove();
										$loaderElement = null;
									}

									$scope.$emit('table.onDraw', controller);

									if ($(this).find('.dataTables_empty').length > 0)
									{
										var $this = $(this);

										calculateRows().then(function(d)
										{
											$this.find('.dataTables_empty').css('height', d.height - 1);
										});
									}

									$(this).find('tbody > tr').addClass('rowlines-' + options.rowLines);

									// Show pointer icon if we can click the TR element.
									$(this).find('tbody > tr').each(function()
									{
										var data;

										try											{ data = table.row(this).data(); }
										catch (ex)									{ /* no problem */ }

										if (!data || data._empty)
										{
											if (options.scroller)					{ $(this).remove(); }
											else									{ $(this).addClass('empty'); }
										}
										else if ($(this).find('i.default, input.checkbox').length > 0)
										{
											$(this).addClass('clickable');
										}

										if ($(this).find('input.checkbox').length > 0 && $(this).find('input.checkbox').prop('checked'))
										{
											$(this).addClass('selected');
										}

										$(this).find('> td').each(function()
										{
											this.replaceNewlines();
										});
									});

									// Fix Angular directives.
									$compile($(this).find('tbody').contents())($scope);

									var $selectAll									= $tableHeaderElement.find('thead input.selectall');

									if (selectedRows.length > 0 && selectedRows.length == table.page.info().recordsTotal)
									{
										$selectAll.prop('checked', false);

										$http({
											'url': (options.uriprefix || '') + '/lib/atsc/datatables/queries/' + queryid + '/ids',
											'method': 'post',
											'headers': { 'Content-Type': 'application/x-www-form-urlencoded' },
											'data': $.param(processAjaxData({}, true))
										}).then(function(response)
										{
											if (response && response.data && response.data.data && response.data.data.items)
											{
												var data							= table.data();
												var row;
												var found;
												var i, j;

												$selectAll.prop('checked', true);

												for (i = 0; i < response.data.data.items.length; i = i + 1)
												{
													found							= false;

													for (var j = 0; j < selectedRows.length; j = j + 1)
													{
														if (selectedRows[j]._id == response.data.data.items[i])
														{
															found					= true;
															break;
														}
													}

													if (!found)						{ $selectAll.prop('checked', false); break; }
												}
											}
										});
									}
									else
									{
										$selectAll.prop('checked', false);
									}

									updateOtherTables();

									if (table.page.len() != pageLength)
									{
										// If the pageLength results in a table that is longer than the page height a scrollbar may appear.
										// This is a workaround to automatically resize the columns if a scrollbar has appeared.
										// Since the row height is fixed, we only need to check this if we have a different amount of rows from the previous render.

										pageLength									= table.page.len();
										checkSize();
									}

									if (firstDraw)
									{
										firstDraw									= false;

										setTimeout(function()
										{
											setWidth();
											resetColumnVisibility();

											$scope.$emit('table.onAfterInit', controller);
										}, 0);
									}
								});

								var removeContentEditable = function(oldValue, e)
								{
									e.target.contentEditable						= false;
									$(e.target).css('text-overflow', '');
									$(e.target).html(oldValue);
								};

								$tableElement.find('tbody').on('click', 'tr', function(e)
								{
									// Entire TR element is clickable, except for the last column (actions column)
									var target										= e.target;

									while (target.tagName != 'TD' && target.tagName != 'TR' && target.tagName != 'TABLE')
									{
										target										= target.parentNode;
									}

									if (target.tagName == 'TD')
									{
										var elements								= $(this).find('> td');
										var index									= elements.index(target);

										if		(options.allowCopy && (e.ctrlKey || e.metaKey))
										{
											target.contentEditable					= true;
											target.spellcheck						= false;

											$(target).css('text-overflow', 'unset');

											target.focus();

											var sel, range;

											if (window.getSelection && document.createRange)
											{
												range								= document.createRange();
												range.selectNodeContents(target);
												sel									= window.getSelection();
												sel.removeAllRanges();
												sel.addRange(range);
											}
											else if (document.body.createTextRange)
											{
												range								= document.body.createTextRange();
												range.moveToElementText(target);
												range.select();
											}

											$(target).on('blur', removeContentEditable.bind(null, $(target).html()));
										}
										else if (options.showCheckbox && (index == 0 || options.toggleRowOnClick !== false))
										{
											var checkbox							= $(this).find('input.checkbox');

											if (e.target != checkbox[0])			{ checkbox.click(); }
										}
										else if (options.showActionBar && index != elements.length - 1)
										{
											var defaultIcon							= $(this).find("i.default");

											if (defaultIcon.length > 0)				{ defaultIcon.trigger($.Event('click', { 'parentEvent': e })); }
										}
									}
								});

								$tableElement.find('tbody').on('click', 'input.checkbox', function(e)
								{
									var row											= table.row($(e.target).parent().parent()).data();
									var index										= -1;

									for (var i = 0; i < selectedRows.length; i = i + 1)
									{
										if (selectedRows[i]._id == row._id)			{ index = i; break; }
									}

									if (index == -1)
									{
										if (options.singleSelectionMode)
										{
											selectedRows.splice(0, selectedRows.length);
											$(e.target).parent().parent().parent().find('input.checkbox').prop('checked', false);
											$(e.target).parents('tr').parent().find('tr').removeClass('selected');
										}

										$(e.target).parents('tr').addClass('selected');

										addSelectedRow(row);

										if (selectedRows.length == table.page.info().recordsTotal)
										{
											$tableHeaderElement.find('thead input.selectall').prop('checked', true);
										}
									}
									else
									{
										selectedRows.splice(index, 1);

										$(e.target).parents('tr').removeClass('selected');

										$tableHeaderElement.find('thead input.selectall').prop('checked', false);
									}

									$(e.target).prop('checked', index == -1);

									updateOtherTables();
									$scope.$emit('table.onToggleSelection', index == -1, row, controller);
								});

								$tableElement.find('tbody').on('click', 'td.actions i', function(e)
								{
									if ($(e.target).attr('data-onclick'))
									{
										($parse($(e.target).attr('data-onclick'))($scope))(table.row($(e.target).parents('tr').first()).data(), controller, $(e.target).attr('data-name'), e.parentEvent || e);
									}
									else
									{
										$scope.$emit('table.onPerformAction', $(e.target).attr('data-name'), table.row($(e.target).parents('tr').first()).data(), controller, e.parentEvent || e);
									}
								});

								$tableHeaderElement.find('thead input.selectall').on('change', function(e)
								{
									selectedRows.splice(0, selectedRows.length);

									if ($(this).prop('checked'))
									{
										$http({
											'url': (options.uriprefix || '') + '/lib/atsc/datatables/queries/' + queryid + '/ids',
											'method': 'post',
											'headers': { 'Content-Type': 'application/x-www-form-urlencoded' },
											'data': $.param(processAjaxData({}, true))
										}).then(function(response)
										{
											if (response && response.data && response.data.data && response.data.data.items)
											{
												var data							= table.data();
												var row;

												for (i = 0; i < response.data.data.items.length; i = i + 1)
												{
													row								= { '_id': response.data.data.items[i], '_incomplete': true };

													for (var j = 0; j < data.length; j = j + 1)
													{
														if (data[j]._id == row._id)
														{
															for (var key in data[j]){ row[key] = data[j][key]; }
															row._incomplete			= false;
														}
													}

													addSelectedRow(row);
												}

												$tableElement.find('tbody input.checkbox').prop('checked', true).parents('tr').addClass('selected');

												$scope.$emit('table.onSelectAll', true, controller);
											}
										}, function()
										{
											$(this).prop('checked', false);

											$scope.$emit('table.onSelectAll', false, controller);
										});
									}
									else
									{
										$tableElement.find('tbody input.checkbox').prop('checked', false).parents('tr').removeClass('selected');

										$scope.$emit('table.onSelectAll', false, controller);
									}
								});

								try
								{
									var $scrollHead									= $element.find('.dataTables_scrollHead');
									var $scrollBody									= $element.find('.dataTables_scrollBody');
									var $scrollbarValue								= $('<div class="datatable-scrollbar-value"></div>');
									var $scrollbar									= $('<div class="datatable-scrollbar"></div>').append($scrollbarValue);

									$tableElement.parent().parent().append($scrollbar);

									$scrollbar[0].onselectstart						= function(){ return false; };

									var leftScale									= 1;

									var getWidth									= function(){ return $scrollHead[0].clientWidth; };
									var getRemainingWidth							= function()
									{
										var ret										= $scrollHead[0].scrollWidth - getWidth();

										if (ret < 5)								{ return 0; }
										else										{ return ret; }
									};
									var getLeft										= function(left){ return Math.max(0, Math.min(getRemainingWidth(), Math.floor(left))); };
									var getLeftScaled								= function(left){ return Math.max(0, Math.min(getRemainingWidth(), Math.floor(leftScale * left)) - 2); };

									checkScrollbar = function()
									{
										var width									= getWidth();
										var remainingWidth							= getRemainingWidth();

										if (remainingWidth > 0)
										{
											$scrollbar.removeClass('hidden').css('width', width + 'px');

											var valueWidth							= width - remainingWidth;

											if (valueWidth > 0)						{ leftScale = 1; }
											else
											{
												leftScale							= 1 - (Math.abs(valueWidth) / remainingWidth);
												valueWidth							= 1;
											}

											$scrollbarValue.css({ 'width': valueWidth + 'px', 'left': getLeftScaled($scrollHead[0].scrollLeft) + 'px' });
										}
										else
										{
											$scrollbar.addClass('hidden');
										}
									};

									$tableElement.on('column-sizing.dt', checkScrollbar);

									checkScrollbar();

									var startX, startLeft;

									var setScrollbar = function(e)
									{
										var offset									= e.clientX - startX;
										var left									= getLeft(startLeft + offset);

										$scrollHead[0].scrollLeft					= left;
										$scrollBody[0].scrollLeft					= left;

										$scrollbarValue.css('left', getLeftScaled(left) + 'px');
									};

									var stopScrollbar = function(e)
									{
										toggleScrollbar(false, e);
									};

									var toggleScrollbar = function(on, e)
									{
										if (on)
										{
											toggleScrollbar(false);

											$scrollbar.addClass('selected');

											startX									= e.clientX;
											startLeft								= $scrollHead[0].scrollLeft;

											$(window).on('mousemove', setScrollbar).on('mouseup', stopScrollbar);
										}
										else
										{
											$scrollbar.removeClass('selected');

											$(window).off('mousemove', setScrollbar).off('mouseup', stopScrollbar);
										}
									}

									$scrollbarValue.on('mousedown', toggleScrollbar.bind(this, true));

									var scroll = function(scrollStep)
									{
										var left									= getLeft($scrollHead[0].scrollLeft + scrollStep);

										$scrollHead[0].scrollLeft					= left;
										$scrollBody[0].scrollLeft					= left;

										$scrollbarValue.css('left', getLeftScaled(left) + 'px');
									};

									$scrollbar.on('click', function(e)
									{
										if (e.target == $scrollbar[0])
										{
											var offset								= e.clientX - $(this).offset().left - $scrollbarValue[0].clientWidth;
											var scrollStep							= getWidth() / 4;

											scroll(offset > 0 ? scrollStep : -scrollStep);
										}
									});

									var doScroll = function(e)
									{
										var scrollStep								= getWidth() / 24;

										scroll(e.originalEvent.deltaY > 0 ? scrollStep : -scrollStep);
									}

									$scrollbar.on('wheel', doScroll);

									$tableElement.on('wheel', function(e)
									{
										if (document.body.clientHeight == document.body.scrollHeight && $scrollBody[0].clientHeight == $scrollBody[0].scrollHeight)
										{
											doScroll(e);
										}
									});
								}
								catch (ex)
								{
									if (console)									{ console.log(ex); }
								}
							};

							$tableElement.on('preInit.dt', preInit);

							var initTable = function()
							{
								var isFirefox									= navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
								var pageScrollPos								= 0;

								table = $tableElement.DataTable({
									'columns': calculateColumns(),
									'serverSide': true,
									'autoWidth': false,
									'pageLength': options.pageLength || 10,
									'lengthChange': options.lengthChange === true,
									'ajax': {
										'url': (options.uriprefix || '') + '/lib/atsc/datatables/queries/' + queryid + '/list',
										'type': 'POST',
										'data': function(d)
										{
											$element.find('.datatable-refresh.fa-refresh').addClass('fa-spin');

											processAjaxData(d, false);
										},
										'onError': function(settings, techNote, message)
										{
											$scope.$emit('table.onError', settings, techNote, message, controller);
										}
									},
									'ordering': options.ordering,
									'order': options.orders,
									'language': JSON.parse($element.attr('data-language')),
									'paging': options.paging !== false,
									'pagingType': options.pagingType,
									'info': options.info !== false,
									'searching': false,
									'deferRender': true,
									'scroller': options.scroller ? { 'rowHeight': d.rowHeight - (isFirefox ? 1 : 0), 'displayBuffer': 10 } : false,
									'scrollY': options.scroller ? d.height : undefined
								});

								if (options.columnFilter)
								{
									new $.fn.dataTable.ColReorder(table, { 'enabled': true, 'realtime': true });

									table.on('column-reorder', checkState);

									colReorderEnabled								= true;
								}

								setState();
							};

							var preInitTable = function()
							{
								if ($scope.showFiltersInline)						{ }
								else												{ initTable(); }
							}

							if (options.showLoader)
							{
								// Add a loader for the initial request. Use the provided ajax loader image if possible, otherwise fall back on default loader image.
								var loaderSrc										= $rootScope.ajaxLoaderImageSrc;

								// Default loader if none is set.
								if (!loaderSrc)										{ loaderSrc = '/lib/atsc/datatables/assets/controller/1.0.0/img/ajax-loader.gif'; }

								// Load the image to determine its height. We need this to calculate the vertical center of the loader.
								var image											= new Image();

								var afterImageLoad = function()
								{
									$loaderElement									= $('<div style="position: absolute; top: 50%; left: 0px; right: 0px; text-align: center; margin-top: -' + (image.height / 2).toFixed(0) + 'px;"></div>').append($('<img />').attr('src', loaderSrc));

									$element.append($loaderElement);

									preInitTable();
								};

								image.onload										= afterImageLoad;
								image.onerror										= afterImageLoad;
								image.src											= loaderSrc;
							}
							else													{ preInitTable(); }

							$scope.processTable = function()
							{
								controller.setSearchFilters($scope.filters);

								$scope.showFiltersInline = false;

								preInitTable();
							};

							var getAvailWidth = function()
							{
								return parseInt(Math.min(800, 90 * ($(window).width() / 100)).toFixed(0));
							};

							var getAvailHeight = function()
							{
								return parseInt(Math.min(600, 90 * ($(window).height() / 100)).toFixed(0)) - 80;
							};

							$scope.getFilters = function()
							{
								var availWidth										= getAvailWidth();
								var availHeight										= getAvailHeight();

								var $filterElement									= $element.find('.new_filters').clone();

								$filterElement.removeAttr('ng-show');
								$filterElement.removeClass('ng-hide');
								$filterElement.find('button').remove();

								var FilterController = function($scope, $mdDialog, $element, $timeout)
								{
									var filters										= angular.copy(searchFilters);
									$scope.filters									= filters;

									$scope.cancel = function()
									{
										$mdDialog.cancel();
									};

									$scope.search = function()
									{
										$mdDialog.hide($scope.filters);
									};

									$scope.reset = function()
									{
										$mdDialog.hide([]);
									};
								};

								FilterController.$inject = ['$scope', '$mdDialog', '$element', '$timeout'];

								$mdDialog.show({
									'template': '<md-toolbar><div class="md-toolbar-tools"><h2>' + translate('datatables/filters') + '</h2></div></md-toolbar>' +
															'<md-dialog-content style="width: ' + availWidth + 'px; height: ' + availHeight + 'px;"><md-content class="md-padding" style="height: ' + (availHeight - 8) + 'px;">' + $($filterElement).outerHTML() + '</md-content></md-dialog-content>' +
															'<md-dialog-actions><md-button ng-click="search()">' + translate('keyword/search') + '</md-button><md-button ng-click="cancel()">' + translate('keyword/cancel') + '</md-button><md-button ng-click="reset()">' + translate('keyword/reset') + '</md-button></md-dialog-actions>',
									'controller': FilterController,
									'multiple': true
								}).then(function(filters)
								{
									$scope.filters								= filters;

									controller.setSearchFilters(filters);
								});
							};

							$scope.getFilterColumns = function()
							{
								controller.reload(true);
							};

							$scope.getColumnVisibility = function()
							{
								var availWidth										= getAvailWidth();
								var availHeight										= getAvailHeight();

								var $columnElement									= $element.find('.columns-screen').clone();

								$columnElement.removeAttr('ng-show');
								$columnElement.removeClass('ng-hide');

								var visibleColumns									= {};

								for (var i = 0; i < columns.length; i = i + 1)
								{
									if (columns[i].hidden !== true)
									{
										visibleColumns[columns[i].name] = userHiddenColumns.indexOf(columns[i].name) == -1;
									}
								}

								var ColumnController = function($scope, $mdDialog, $element)
								{
									$scope.columns = visibleColumns;
									$scope.cancel = function()
									{
										$mdDialog.cancel();
									};

									$scope.ok = function()
									{
										var columns = [];

										for (var key in $scope.columns)
										{
											if (!$scope.columns[key])				{ columns.push(key); }
										}

										$mdDialog.hide(columns);
									};
								};

								ColumnController.$inject = ['$scope', '$mdDialog', '$element'];

								$mdDialog.show({
									'template': '<md-toolbar><div class="md-toolbar-tools"><h2>' + translate('datatables/columns') + '</h2></div></md-toolbar>' +
															'<md-dialog-content style="width: ' + availWidth + 'px; height: ' + availHeight + 'px;"><md-content class="md-padding datatable-columnslist" style="height: ' + (availHeight - 8) + 'px;"><div class="datatable-columnslist-body">' + $($columnElement).outerHTML() + '</div></md-content></md-dialog-content>' +
															'<md-dialog-actions><md-button ng-click="ok()">' + translate('keyword/ok') + '</md-button><md-button ng-click="cancel()">' + translate('keyword/cancel') + '</md-button></md-dialog-actions>',
									'controller': ColumnController,
									'multiple': true
								}).then(function(columns)
								{
									userHiddenColumns								= columns ? columns : [];
									resetColumnVisibility();

									checkState();
								});
							};

							$scope.getStates = function()
							{
								var availWidth										= getAvailWidth();
								var availHeight										= getAvailHeight();

								var $statesElement									= $('<div></div>');

								$statesElement.append($('<div></div>')
														.append($('<input type="text" style="width: ' + (stateSupportsGlobal ? 'calc(100% - 100px)' : '100%') + ';" ng-model="state" maxlength="25" />'))
														.append('<label ng-if="stateSupportsGlobal"><input type="checkbox" ng-model="$parent.global" /> ' + translate('datatables/global') + '</label>')
								);

								$statesElement.append($('<div class="datatable-stateslist"></div>')
														.append($('<ul></ul>')
															.append($('<li ng-repeat="state in availableStates"><a ng-click="setState(state)" ng-bind="state.name" ng-class="{&quot;datatables-global&quot;: stateSupportsGlobal && state.global}"></a></li>'))
														)
								);

								var ColumnController = function($scope, $mdDialog, $element)
								{
									$scope.stateSupportsGlobal						= stateSupportsGlobal;
									$scope.availableStates							= availableStates;

									$scope.setState = function(state)
									{
										$scope.state								= state.name;
										$scope.global								= state.global;
									};

									$scope.cancel = function()
									{
										$mdDialog.cancel();
									};

									$scope.load = function()
									{
										loadState($scope.state, $scope.global);

										$mdDialog.hide();
									};

									$scope.save = function()
									{
										saveState($scope.state, $scope.global);

										$mdDialog.hide();
									};

									$scope.remove = function()
									{
										deleteState($scope.state, $scope.global);

										$mdDialog.hide();
									};
								};

								ColumnController.$inject = ["$scope", "$mdDialog", "$element"];

								$mdDialog.show({
									'template': '<md-toolbar><div class="md-toolbar-tools"><h2>' + translate('datatables/states') + '</h2></div></md-toolbar>' +
															'<md-dialog-content style="width: ' + availWidth + 'px; height: ' + availHeight + 'px;"><md-content class="md-padding datatable-stateslist" style="height: ' + (availHeight - 8) + 'px;"><div class="datatable-stateslist-body">' + $($statesElement).outerHTML() + '</div></md-content></md-dialog-content>' +
															'<md-dialog-actions><md-button ng-click="load()" ng-disabled="!state">' + translate('datatables/load') + '</md-button><md-button ng-click="save()" ng-disabled="!state">' + translate('datatables/save') + '</md-button><md-button ng-click="remove()" ng-disabled="!state">' + translate('datatables/delete') + '</md-button><md-button ng-click="cancel()">' + translate('datatables/cancel') + '</md-button></md-dialog-actions>',
									'controller': ColumnController,
									'multiple': true
								});
							};

							$scope.exportTo = function(uri, filename)
							{
								$http({
									url: uri,
									method: 'post',
									headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
									data: $.param(table.ajax.params()),
									responseType: 'blob'
								}).then(function(response)
								{
									download(response.data, filename);
								});
							};

							if (options.searching !== false)
							{
								$scope.$watch('search', function(newValue)
								{
									lastActionHero('datatables.search', function()
									{
										searchTerm									= newValue ? newValue : '';

										checkState();

										controller.reloadIfChanged();
									}, false, 250);
								});
							}
						}
						catch (ex)
						{
							if (console)											{ console.log(ex); }
						}
					};

					if (options.autoSize)
					{
						calculateRows().then(function(d)
						{
							options.pageLength										= d.rows;

							continueLoading(d);
						});
					}
					else
					{
						continueLoading(0);
					}
				});
			}
		}
		// $timeout is necessary to prevent "Cannot read property 'childNodes' of undefined" error thrown by AngularJS. Occurs when "ng-if" directive is used.
		$timeout(load, 0, false);
	}]);
})();

(function()
{
	'use strict';
	angular.module('datatables').service('DataTableRenderer', [function()
	{
		var service																= this;
		var renderers															= {};

		service.addRenderer = function(type, handler)
		{
			renderers[type] = handler;
		};

		service.getRenderer = function(type)
		{
			return renderers[type];
		};

		var renderDate = function(type, tableController, name, value, display, data)
		{
			if		(data._empty)												{ return ''; }
			else if (value)
			{
				var m;

				m																= value.toMoment();

				if (m)
				{
					switch (type)
					{
						case 'date':											{ return m.format(moment.localeData().longDateFormat('L')); }
						case 'time':											{ return m.format(moment.localeData().longDateFormat('LT')); }
						case 'timestamp-with-seconds':							{ return m.format(moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LTS')); }
						default:												{ return m.format(moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT')); }
					}
				}
				else															{ return ''; }
			}
			else																{ return ''; }
		}

		service.addRenderer('date', renderDate.bind(null, 'date'));
		service.addRenderer('time', renderDate.bind(null, 'time'));
		service.addRenderer('timestamp-with-seconds', renderDate.bind(null, 'timestamp-with-seconds'));
		service.addRenderer('timestamp', renderDate.bind(null, 'timestamp'));

		service.addRenderer('LocalDate', function(tableController, name, value, display, data)
		{
			if		(data._empty)												{ return ''; }
			else if (value)
			{
				var m															= value.toMoment();

				if (m)															{ return m.format(moment.localeData().longDateFormat('L')); }
				else															{ return ''; }
			}
			else																{ return ''; }
		});

		service.addRenderer('LocalDateTime', function(tableController, name, value, display, data)
		{
			if		(data._empty)												{ return ''; }
			else if (value)
			{
				var m															= ('' + value).toMoment();

				if (m)															{ return m.format(moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT')); }
				else															{ return ''; }
			}
			else																{ return ''; }
		});

		return service;
	}]);
})();

(function()
{
	'use strict';
	angular.module('datatables')
	.service('LookupService', ['$mdDialog', '$q', '$http', function($mdDialog, $q, $http)
	{
		var service																			= {};
		var defaultOptions																	= {};
		var uriprefix;
		var fixedWidth, fixedHeight;

		service.getDialogHeight																= function(){ return (($(window).height() / 100) * 80) - 52; };
		service.getDialogContentHeight														= function(){ return service.getDialogHeight() - 140; };
		service.setURIPrefix																= function(prefix){ uriprefix = prefix; };
		service.getURIPrefix																= function(){ return uriprefix; };

		service.setFixedSize = function(width, height)
		{
			if (width > 0 && height > 0)
			{
				fixedWidth																	= width;
				fixedHeight																	= height;
			}
			else
			{
				fixedWidth																	= null;
				fixedHeight																	= null;
			}
		};

		service.setDefaultOptions = function(options)
		{
			defaultOptions																	= options ? options : {};
		};

		service.getLookupDocument = function(queryid, lookupOptions, options, selected, description)
		{
			options																			= $.fn.extend({}, defaultOptions, options ? options : {});

			return $q(function(resolve, reject)
			{
				var url																		= '/lib/atsc/datatables/queries/' + queryid + '/lookup';
				if (uriprefix)																{ url = uriprefix + url; }

				var data																	= lookupOptions;

				data.options																= options;

				data.options.showActionBar													= false;
				data.options.lengthChange													= false;
				data.options.pageLength														= Math.floor((fixedHeight ? fixedHeight : service.getDialogContentHeight()) / 37) - 1;
				data.options.showCheckbox													= true;
				data.options.autoSize														= true;
				data.options.columnFilter													= ('' + options.columnFilter) !== 'false';
				data.options.allowCopy														= true;

				if (data.allowMultiple)														{ data.options.showSelectAll = true; }
				else																		{ data.options.singleSelectionMode = true; data.options.showSelectAll = false; }

				if (uriprefix)																{ data.options.uriprefix = uriprefix; }

				$http({
					'url': url,
					'method': 'post',
					'data': data,
				}).then(function(response){ resolve(response.data); }, reject);
			});
		};

		service.lookup = function(queryid, lookupOptions, options, selected, description)
		{
			var args																		= Array.prototype.slice.call(arguments);

			return $q(function(resolve, reject)
			{
				service.getLookupDocument.apply(null, args).then(function(doc)
				{
					var LookupController = function LookupController($scope, $mdDialog, $http, $compile, $q, $util, $timeout, LookupService, selected, description)
					{
						var controller														= this;
						$scope.controller													= controller;
						$scope.description													= description;

						if (fixedWidth && fixedHeight)
						{
							$scope.modalCss = { 'position': 'fixed', 'left': '50%', 'right': 'auto', 'top': '50%', 'margin-left': -(fixedWidth / 2) + 'px', 'margin-top': -(fixedHeight / 2) + 'px', 'bottom': 'auto', 'width': fixedWidth + 'px', 'height': fixedHeight +  'px', 'max-width': 'none', 'max-height': 'none' };
						}
						else
						{
							$scope.modalCss = { 'position': 'fixed', 'left': '10px', 'right': '10px', 'top': '10px', 'bottom': '10px', 'width': 'auto', 'height': 'auto', 'max-width': 'none', 'max-height': 'none' };
						}

						var rows = [];

						if (selected)
						{
							for (var i = 0; i < selected.length; i = i + 1)					{ rows.push(selected[i]._id); }
						}

						if (rows.length > 0)
						{
							var setSelected = function()
							{
								if (!$scope.childTableControllers || !$scope.childTableControllers.length)
								{
									setTimeout(setSelected, 10);
								}
								else
								{
									var tableController										= $scope.childTableControllers[0];

									tableController.setSelectedRows(rows);
								}
							}

							setSelected();
						}

						$scope.$on('table.onToggleSelection', function(e, b, row, tableController)
						{
							if (b && tableController.getOption('singleSelectionMode'))		{ controller.select(); }
						});

						controller.select = function()
						{
							var tableController												= $scope.childTableControllers[0];

							tableController.getSelectedRows().then(function(rows)
							{
								$mdDialog.hide(rows);
							});
						}

						controller.selectNone = function()
						{
							$mdDialog.hide([]);
						};

						controller.close = function()
						{
							$mdDialog.cancel();
						};
					};
					LookupController.$inject												= [ '$scope', '$mdDialog', '$http', '$compile', '$q', '$util', '$timeout', 'LookupService', 'selected', 'description' ];
					$mdDialog.show({
						'template': doc,
						'locals': {
							'selected': selected,
							'description': description
						},
						'controller': LookupController,
						'skipHide': true, // Angular 1.5.8
						'multiple': true // Angular 1.5.9+
					}).then(resolve, reject);
				});
			});
		};

		return service;
	}])
})();

(function()
{
	'use strict';

	var lookupModelCounter = 0;

	angular.module('datatables')
	.directive('lookupModel', [ '$compile', '$parse', 'LookupService', '$http', '$util', '$q', function($compile, $parse, LookupService, $http, $util, $q)
	{
		return {
			restrict: 'A',
			priority: -1,
			require: ['^?mdInputContainer'],
			compile: function(element, attrs, ctrls)
			{
				var lookupModel												= attrs.lookupModel;
				var lookupModelId											= attrs.lookupModelId;
				var displayModel											= attrs.lookupDisplayModel;
				var lookupQuery												= attrs.lookupQuery;
				var lookupValue												= attrs.lookupValue;
				var lookupFilters											= attrs.lookupFilters ? JSON.parse(attrs.lookupFilters) : null;
				var lookupOptions											= attrs.lookupOptions ? JSON.parse(attrs.lookupOptions) : {};
				var lookupTableOptions										= attrs.lookupTableOptions ? JSON.parse(attrs.lookupTableOptions) : {};
				var lookupHiddenColumns										= attrs.lookupHiddenColumns ? JSON.parse(attrs.lookupHiddenColumns) : [];
				var lookupMultiple											= attrs.lookupMultiple ? true : false;
				var lookupUriPrefix											= attrs.lookupUriPrefix || LookupService.getURIPrefix() || '';
				var processValue											= attrs.lookupProcessValue != undefined;
				var readonly												= false;
				var lookupPlaceholder										= attrs.lookupPlaceholder;
				var lookupOnClick											= attrs.lookupOnclick;
				var lookupAllowCopy											= attrs.lookupAllowCopy ? true : false;

				if (lookupMultiple)											{ lookupOptions.allowMultiple = true; }

				element.removeAttr('lookup-model');
				element.removeAttr('lookup-model-id');
				element.removeAttr('lookup-display-model');
				element.removeAttr('lookup-display-model-assignable');
				element.removeAttr('lookup-query');
				element.removeAttr('lookup-value');
				element.removeAttr('lookup-filters');
				element.removeAttr('lookup-options');
				element.removeAttr('lookup-table-options');
				element.removeAttr('lookup-hidden-columns');
				element.removeAttr('lookup-process-value');
				element.removeAttr('lookup-multiple');
				element.removeAttr('lookup-placeholder');
				element.removeAttr('lookup-onclick');
				element.removeAttr('lookup-allow-copy');

				lookupTableOptions.filters									= lookupFilters;
				lookupTableOptions.hiddenColumns							= lookupHiddenColumns;

				var ngModel													= 'lookupModel' + (++lookupModelCounter);
				displayModel												= displayModel || (lookupModel ? lookupModel + '.id' : lookupModelId);

				element.attr('ng-value', ngModel);

				if (element.attr('readonly') !== undefined)					{ readonly = true; }
				else														{ element.attr('readonly', 'readonly'); }

				var divElement												= angular.element(document.createElement('DIV'));

				divElement.addClass('lookup');

				divElement.css({ 'display': 'inline-block', 'position': 'relative' });

				divElement.insertBefore(element);

				divElement.append(element);

				if (!readonly)
				{
					var childDivElement										= angular.element(document.createElement('DIV'));

					childDivElement.css({ 'position': 'absolute', 'right': '0px', 'top': '0px', 'bottom': '0px', 'width': '24px', 'border-left': element.is('input, textarea') ? '1px solid #DCDCDC' : '0px', 'padding': '4px' });

					// The border-left may be unnecessary. Check the style of the element to see if the border is necessary.
					// Unfortunately, the style cannot be retrieved at this point, so we have to loop it.
					var r													= 0; // Also unfortunately, AngularJS produces hidden elements that are removed from the DOM and get stuck in this loop...

					var checkBorder = function()
					{
						var cs												= window.getComputedStyle(element[0], null);

						if (cs.length > 0 || ++r > 20)
						{
							if (cs.borderRightWidth && cs.borderRightWidth != '0px')
							{
								childDivElement.css('border-left', '1px solid #DCDCDC');
							}
							else
							{
								childDivElement.css('border-left', '');
							}
						}
						else												{ setTimeout(checkBorder, 100); }
					};

					checkBorder();

					var iconElement											= angular.element(document.createElement('I'));
					iconElement.addClass('fa');
					iconElement.addClass('fa-search');

					childDivElement.append(iconElement);
					divElement.append(childDivElement);

					element.css({ 'padding-right': '24px' });
				}

				var parseResponse = function(rows)
				{
					var items												= [];

					if (rows && rows.length > 0)
					{
						var item;

						for (var i = 0; i < rows.length; i = i + 1)
						{
							item											= {};

							for (var key in rows[i])
							{
								if (key == '_id' || !key.startsWith('_'))	{ item[key] = rows[i][key]; }
							}

							items.push(item);
						}
					}

					return items;
				}

				return {
					pre: function(scope, element, attrs, ctrls)
					{
						// Let other scripts (notably atsc-asset-form) know that there's a lookup pending.
						if (!scope.pendingLookupDirectives)					{ scope.$parent.pendingLookupDirectives = 0; }
						scope.$parent.pendingLookupDirectives++;

						var value											= lookupValue || element.val();

						if (processValue)									{ value = $parse(value)(scope); }

						if (value)
						{
							var values										= value.split(/_<sep>_/g);
							var items										= [];

							$util.forEach(values).each(function(value)
							{
								return $q(function(next, exit)
								{
									$http({
										'url': lookupUriPrefix + '/lib/atsc/datatables/queries/' + lookupQuery + '/model/' + value,
										'type': 'post'
									}).then(function(response)
									{
										if (response && response.data && response.data.data && response.data.data.items && response.data.data.items.length > 0)
										{
											items.push(parseResponse(response.data.data.items)[0]);
										}

										next();
									}, function()
									{
										next();
									});
								});
							}).then(function()
							{
								try
								{
									if (lookupMultiple)
									{
										if (lookupModel)					{ $parse(lookupModel).assign(scope, items); }

										if (lookupModelId)
										{
											var ids							= [];

											for (var i = 0; i < items.length; i++)
											{
												ids.push(items[i]._id);
											}

											$parse(lookupModelId).assign(scope, ids);
										}
									}
									else
									{
										if (lookupModel)					{ $parse(lookupModel).assign(scope, items.length > 0 ? items[0] : null); }
										if (lookupModelId)					{ $parse(lookupModelId).assign(scope, items.length > 0 ? items[0]._id : null); }
									}
								}
								finally										{ scope.$parent.pendingLookupDirectives--; }
							}).run();
						}
						else												{ scope.$parent.pendingLookupDirectives--; }
					},
					post: function postLink(scope, element, attrs, ctrls)
					{
						$compile(element)(scope);

						if (!readonly)
						{
							// lookupAllowCopy

							var doLookup = function()
							{
								this.blur();
								document.body.focus();

								var items									= null;

								if (lookupMultiple)
								{
									if		(lookupModel)
									{
										items								= $parse(lookupModel)(scope);
									}
									else if (lookupModelId)
									{
										var ids								= $parse(lookupModelId)(scope);
										items								= [];

										for (var i = 0; i < ids.length; i++){ items.push({ '_id': ids[i] }); }
									}

									if (items && (!(items instanceof Array)))
									{
										items								= [items];
									}
								}

								var promise;

								if (lookupOnClick)							{ promise = $parse(lookupOnClick)(scope)(lookupQuery, lookupOptions, lookupTableOptions, items); }
								else										{ promise = LookupService.lookup(lookupQuery, lookupOptions, lookupTableOptions, items); }

								promise.then(function(rows)
								{
									var items								= parseResponse(rows);

									if (lookupMultiple)
									{
										if (lookupModel)					{ $parse(lookupModel).assign(scope, items); }

										if (lookupModelId)
										{
											var ids							= [];

											for (var i = 0; i < items.length; i++)
											{
												ids.push(items[i]._id);
											}

											$parse(lookupModelId).assign(scope, ids);
										}
									}
									else
									{
										if (lookupModel)					{ $parse(lookupModel).assign(scope, items.length > 0 ? items[0] : null); }
										if (lookupModelId)					{ $parse(lookupModelId).assign(scope, items.length > 0 ? items[0]._id : null); }
									}
								});
							};

							if (!lookupAllowCopy)
							{
								element.css({ 'cursor': 'pointer', 'outline': '0' }, true).on('click', doLookup);
								element.find('> input').css('cursor', 'pointer');
							}
							else
							{
								element.find('> div').css({ 'cursor': 'pointer', 'outline': '0' }, true).on('click', doLookup);
							}
						}

						if (ctrls[0])										{ ctrls[0].input = undefined; }

						scope.$watch(displayModel, function(newValue){ scope[ngModel] = newValue ? newValue : lookupPlaceholder; });
					}
				};
			}
		}
	}])
})();

(function(){
	'use strict';

	angular.module('datatables')
	.directive('filterText', function()
	{
		return {
			restrict: 'E',
			require: ['?ngModel'],
			scope:
			{
				operations: '=',
			},
			template: 	'<div>' +
							'<label></label>' +
							'<select ng-if="operations.length > 0" style="margin-right: 5px;" ng-model="$parent.operation"><option ng-value="op" ng-repeat="op in operations" ng-bind="translateOperation(op)"></option></select>' +
							'<input type="text" ng-model="value" ng-disabled="operation == \'ISNULL\' || operation == \'ISNOTNULL\'" />' +
						'</div>',
			link: function (scope, element, attributes, ctrls)
			{
				element.find('label').append(attributes.filterlabel);

				scope.translateOperation = function(op)
				{
					return translate('operations/' + op.toLowerCase());
				};

				scope.$watchGroup(['operation', 'value'], function()
				{
					var v;

					if (scope.operation == 'ISNULL' || scope.operation == 'ISNOTNULL')		{ scope.value = ''; }

					if		(scope.value)													{ v = (scope.operation ? '_<' + scope.operation.toLowerCase() + '>_' : '') + scope.value; }
					else if (scope.operation == 'ISNULL' || scope.operation == 'ISNOTNULL')	{ v = '_<' + scope.operation.toLowerCase() + '>_'; }
					else																	{ v = null; }

					if (ctrls[0])															{ ctrls[0].$setViewValue(v); }
				});

				ctrls[0].$render = function()
				{
					if (ctrls[0].$viewValue)
					{
						if (ctrls[0].$viewValue.indexOf('_<') > -1)
						{
							scope.value														= ctrls[0].$viewValue.right('>_');
							scope.operation													= ctrls[0].$viewValue.right('_<').left('>_').toUpperCase();

							if (scope.operations.indexOf(scope.operation) == -1)			{ scope.operation = ''; }
						}
						else
						{
							scope.value														= ctrls[0].$viewValue;
							scope.operation													= '';
						}
					}
					else
					{
						scope.value															= '';
						scope.operation														= '';
					}
				};
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('datatables')
	.directive('filterMultipleChoice', function()
	{
		return {
			restrict: 'E',
			require: ['?ngModel'],
			scope:
			{
				choices: '=',
			},
			template: 	'<div style="display: inline-flex; flex-direction: row;">' +
							'<label></label>' +
							'<select multiple="multiple" ng-model="choice" style="padding: 5px 10px; height: auto;"><option ng-value="key" ng-repeat="(key, value) in choices" ng-bind="value"></option></select>' +
							'<i class="fa fa-fw fa-question" style="cursor: help; margin-top: 5px;"></i>' +
						'</div>',
			link: function (scope, element, attributes, ctrls)
			{
				element.find('label').append(attributes.filterlabel);
				element.find('i.fa-question').attr('title', translate('datatables/multiplechoice-help'));

				scope.$watchGroup(['choice'], function()
				{
					var v;

					if (scope.choice)						{ v = '_<in>_' + scope.choice.join('_<sep>_'); }
					else									{ v = null; }

					if (ctrls[0])							{ ctrls[0].$setViewValue(v); }
				});

				ctrls[0].$render = function()
				{
					if (ctrls[0].$viewValue)				{ scope.choice = ctrls[0].$viewValue.right('>_').split(/_<sep>_/g); }
					else									{ scope.choice = null; }
				};
			}
		};
	});
})();

(function(){
	'use strict';

	angular.module('datatables')
	.directive('filterBoolean', function()
	{
		return {
			restrict: 'E',
			require: ['?ngModel'],
			template: 	'<div style="display: inline-flex;">' +
							'<label></label>' +
							'<div class="datatables-buttongroup">' +
								'<button ng-class="{&quot;pressed&quot;: value == \'_<exact>_true\'}" ng-click="value = \'_<exact>_true\'"><i class="fa fa-check"></i></button>' +
								'<button ng-class="{&quot;pressed&quot;: value == \'_<exact>_false\'}" ng-click="value = \'_<exact>_false\'"><i class="fa fa-close"></i></button>' +
								'<button ng-class="{&quot;pressed&quot;: value == null}" ng-click="value = null">Default</button>' +
							'</div>' +
						'</div>',
			link: function (scope, element, attributes, ctrls)
			{
				element.find('label').append(attributes.filterlabel + ': ');

				scope.$watch('value', function(newValue)
				{
					ctrls[0].$setViewValue(newValue);
				});

				ctrls[0].$render = function(){ scope.value = ctrls[0].$viewValue; };
			}
		};
	});
})();

